<template>
  <main class="bg-white">
    <section class="core evaluation">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="90px"
        class="evaluation-list"
        label-position="left"
      >
        <div class="evaluation-item">
          <div class="evaluation-title lg mb20 row lighter">物流服务评价</div>
          <div class="item-box">
            <div class="item-title bg-center">
              订单编号：<span class="primary">{{ goodsDetail.order_sn }}</span
              >{{ goodsDetail.confirm_take_time }}
            </div>
            <div class="item-model row" style="align-items: center">
              <div class="model-content row">
                <img
                  class="mr20"
                  src="@/assets/icon/icon-logistics.png"
                  alt=""
                />
                物流服务评价
              </div>
              <div class="model-list">
                <el-form-item label="配送服务" prop="express_comment">
                  <el-rate
                    v-model="ruleForm.express_comment"
                    :colors="{ '5': '#c91623' }"
                  ></el-rate>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
        <div class="evaluation-item">
          <div class="evaluation-title lg mb20 row lighter">商品评价</div>
          <div class="item-box">
            <div class="item-model row">
              <div class="model-pro center">
                <div class="pro-box">
                  <el-image
                    style="width: 186px; height: 186px"
                    v-if="goodsComment.goods_item"
                    :src="goodsComment.goods_item.image"
                    class="mb20"
                    fit="cover"
                  ></el-image>
                  <p class="title line1 mb5">{{ goodsComment.goods_name }}</p>
                  <p
                    class="size line1 lighter xs"
                    v-if="goodsComment.goods_item"
                  >
                    {{ goodsComment.goods_item.spec_value_str }}
                  </p>
                </div>
              </div>
              <div class="model-list">
                <el-form-item label="商品评价" prop="goods_comment">
                  <el-rate
                    v-model="ruleForm.goods_comment"
                    :colors="{ '5': '#c91623' }"
                  ></el-rate>
                </el-form-item>
                <el-form-item label="描述相符" prop="description_comment">
                  <el-rate
                    v-model="ruleForm.description_comment"
                    :colors="{ '5': '#c91623' }"
                  ></el-rate>
                </el-form-item>
                <el-form-item label="服务态度" prop="service_comment">
                  <el-rate
                    v-model="ruleForm.service_comment"
                    :colors="{ '5': '#c91623' }"
                  ></el-rate>
                </el-form-item>
                <!-- <el-form-item label="评价" prop="evaluation">
                  <el-input
                    type="textarea"
                    v-model="ruleForm.evaluation"
                    maxlength="300"
                    rows="5"
                    show-word-limit
                  ></el-input>
                </el-form-item>
                <el-form-item label="晒图" prop="figure">
                  <el-upload
                    action="#"
                    list-type="picture-card"
                    :auto-upload="false"
                    :limit="5"
                  >
                    <i slot="default" class="el-icon-plus"></i>
                    <div slot="file" slot-scope="{ file }">
                      <img
                        class="el-upload-list__item-thumbnail"
                        :src="file.url"
                        alt=""
                      />
                    </div>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" alt="" />
                  </el-dialog>
                </el-form-item> -->
                <el-form-item>
                  <el-button
                    type="primary"
                    class="mt20"
                    @click="submitForm('ruleForm')"
                    >提交</el-button
                  >
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
      </el-form>
    </section>
  </main>
</template>
<script>
import { vxRule } from "@/utils/validator";
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      orderId: "",
      goodsDetail: {},
      goodsComment: {},
      value: null,
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      ruleForm: {
        goods_comment: 0, //商品评价
        description_comment: 0, //描述相符
        service_comment: 0, //服务态度
        express_comment: 0, //配送服务
        evaluation: "",
      },
      rules: {
        goods_comment: vxRule(
          true,
          (val) => {
            return {
              result: val != 0,
              errMsg: "请对商品评价评分",
            };
          },
          "blur",
          "请对商品评价评分"
        ),
        description_comment: vxRule(
          true,
          (val) => {
            return {
              result: val != 0,
              errMsg: "请对描述相符评分",
            };
          },
          "blur",
          "请对描述相符评分"
        ),
        service_comment: vxRule(
          true,
          (val) => {
            return {
              result: val != 0,
              errMsg: "请对服务态度评分",
            };
          },
          "blur",
          "请对服务态度评分"
        ),
        express_comment: vxRule(
          true,
          (val) => {
            return {
              result: val != 0,
              errMsg: "请对配送服务评分",
            };
          },
          "blur",
          "请对配送服务评分"
        ),
        // evaluation: vxRule(true, null, "blur", "请填写评价"),
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    getCommentPage() {
      this.$api
        .getCommentPageApi({ order_goods_id: this.orderId })
        .then((res) => {
          if (res.code == 1) {
            this.goodsComment = res.data;
          }
        });
      this.$api.getOrderDetailApi({ id: this.orderId }).then((res) => {
        if (res.code == 1) {
          this.goodsDetail = res.data;
        }
      });
    },
    handleRemove(file) {},
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {},
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$api
            .addGoodsCommentApi({
              ...this.ruleForm,
              order_goods_id: this.orderId,
            })
            .then((res) => {
              if (res.code == 1) {
                this.$message({
                  message: res.msg,
                  type: "success",
                  onClose: () => {
                    this.$router.go(-1);
                  },
                });
              }
            });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  created() {
    this.orderId = this.$route.query.id;
    this.getCommentPage();
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.evaluation {
  padding: 65px 0 30px;
  .evaluation-list {
    .evaluation-item {
      .evaluation-title {
        &::before {
          content: "";
          display: inline-block;
          width: 6px;
          height: 20px;
          background-color: $-color-primary;
          margin-right: 15px;
        }
      }
      .item-box {
        border: $-solid-border;
        margin-bottom: 70px;
        .item-title {
          height: 60px;
          line-height: 60px;
          padding-left: 25px;
          span {
            margin-right: 45px;
          }
        }
        .item-model {
          padding: 45px;
          align-items: flex-start;
          .model-content,
          .model-pro {
            width: 295px;
          }
          .model-content {
            img {
              width: 73px;
              height: 73px;
            }
          }
          .model-pro {
            .pro-box {
              width: 186px;
            }
          }
          /deep/ .model-list {
            .el-form-item__label,
            .el-form-item__content {
              line-height: 30px;
            }
            .el-rate {
              display: inline-block;
            }
            .el-upload--picture-card {
              line-height: 76px;
              background-color: $-color-white;
              border-style: solid;
              border-color: $-color-border;
              i {
                line-height: inherit;
                font-weight: bold;
                color: $-color-border;
                font-size: 32px;
              }
            }
            .el-upload--picture-card,
            .el-upload-list__item {
              width: 80px;
              height: 80px;
              border-radius: 0;
              margin-bottom: 0;
            }
            .el-textarea__inner {
              width: 440px;
              border-radius: 0;
              resize: none;
            }
            .el-button {
              border-radius: 2px;
              width: 160px;
              padding: 0;
              height: 40px;
              text-align: center;
              line-height: 40px;
            }
          }
        }
      }
    }
  }
}
</style>
